.leaflet-control-container .leaflet-routing-container-hide {
    display: none;
}

.leaflet-tooltip-bottom:before {
    border-bottom-color: #171c20 !important;
}

.leaflet-tooltip {
    border: none;
    background-color: #171c20 !important;
    color: white !important;
    text-align: center;
}
.car-right-door.unlock{
    transform-origin: 63% 39%;
    transform: rotate(-30deg);
    animation: right-door-open 1s ease-out;
}
.car-right-door.lock{
    transform-origin: 63% 39%;
    transform: rotate(0deg);
    animation: right-door-close 1s ease-out;
}
.car-door{
    position: absolute;
    width: 100%;
    top:30px;
    left:0px;
}
.car-left-door.unlock{
    
    transform: rotate(30deg);
    animation: left-door-open 1s ease-out;
    transform-origin: 37.5% 39%;
}

.car-left-door.lock{
    
    transform: rotate(0deg);
    animation: left-door-close 1s ease-out;
    transform-origin: 37.5% 39%;
}
@keyframes left-door-open {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(30deg);
    }
}
@keyframes left-door-close {
    from {
        transform: rotate(30deg);
    }

    to {
        transform: rotate(0deg);
    }
}
@keyframes right-door-close {

    from {
        transform: rotate(-30deg);
    }
    
    to {
        transform: rotate(0deg);
    }
}
@keyframes right-door-open {
    from {
        transform: rotate(0deg);
    }
    
    to {
        transform: rotate(-30deg);
    }
}
